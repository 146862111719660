
import nameof from "ts-nameof.macro";

import { EnumDropDownBase } from "../utils/base/EnumDropDownBase";
import { Editorial, TipoAcceso, YesNo, TipoUsuario, EstadoUsuario, TipoUsuarioContenido, EstadoNotificacion, OrigenLicencia, EstadoPedido, EstadoPedidoScholarum, TipoContenido, FormatoContenido } from "../models/dataModels";


export class YesNoDropDown extends EnumDropDownBase {
    Elem(): any {
        return YesNo;
    }
    ElemName(): any {
        return nameof(YesNo);
    }
};

export class EditorialDropDown extends EnumDropDownBase {
    Elem(): any {
        return Editorial;
    }
    ElemName(): any {
        return nameof(Editorial);
    }
};


export class TipoUsuarioContenidoDropDown extends EnumDropDownBase {
    Elem(): any {
        return TipoUsuarioContenido;
    }
    ElemName(): any {
        return nameof(TipoUsuarioContenido);
    }
};

export class TipoAccesoDropDownAll extends EnumDropDownBase {
    Elem(): any {
        return TipoAcceso;
    }
    ElemName(): any {
        return nameof(TipoAcceso);
    }
};

export class TipoUsuarioDropDown extends EnumDropDownBase {
    Elem(): any {
        return TipoUsuario;
    }
    ElemName(): any {
        return nameof(TipoUsuario);
    }
}

export class EstadoUsuarioDropDown extends EnumDropDownBase {
    Elem(): any {
        return EstadoUsuario;
    }
    ElemName(): any {
        return nameof(EstadoUsuario);
    }
}

export class EstadoNotificacionDropDown extends EnumDropDownBase {
    Elem(): any {
        return EstadoNotificacion;
    }
    ElemName(): any {
        return nameof(EstadoNotificacion);
    }
};

export class EstadoPedidoDropDown extends EnumDropDownBase {
    Elem(): any {
        return EstadoPedido;
    }
    ElemName(): any {
        return nameof(EstadoPedido);
    }
};

export class EstadoPedidoScholarumDropDown extends EnumDropDownBase {
    Elem(): any {
        return EstadoPedidoScholarum;
    }
    ElemName(): any {
        return nameof(EstadoPedidoScholarum);
    }
};

export class OrigenLicenciaDropDown extends EnumDropDownBase {
    Elem(): any {
        return OrigenLicencia;
    }
    ElemName(): any {
        return nameof(OrigenLicencia);
    }
};

export class FormatoContenidoDropDown extends EnumDropDownBase {
    Elem(): any {
        return FormatoContenido;
    }
    ElemName(): any {
        return nameof(FormatoContenido);
    }
};

export class TodosTipoAccesoDropDown extends EnumDropDownBase {
    Elem(): any {
        return TipoAcceso;
    }
    ElemName(): any {
        return nameof(TipoAcceso);
    }
};
