import * as React from "react";

import nameof from "ts-nameof.macro";

import { withRouter } from "react-router-dom";
import { LicenciaContenidosEditModel, OrigenLicencia, TipoAcceso, TipoUsuarioContenido } from "../../models/dataModels";
import { GenericEditState } from "../../utils/base/GenericEditState";
import { EditPageBase } from "../../utils/base/EditPageBase";
import { IServiceForEdit } from "../../utils/base/BaseServices";
import { LicenciasColegioService } from "../LicenciasColegioService";
import { TipoAccesoDropDown } from "../TipoAccesoDropDown";
import { RouteHelper } from "../../IntranetRouter";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { I18n } from "../../utils/I18n";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { ClientContext } from "../../utils/ClientContext";
import { ClaseMateriaDropDown } from "../../cursoEscolar/materia/ClaseMateriaDropDown";
import { ContenidosPicker } from "../../contenidos/colegio/Picker";
import { MiPerfilService } from "../../miPerfil/MiPerfilService";
import { ClasesDropDown } from "../../clases/profesor/ClasesDropDown";
import { NavigationHelper } from "../../utils/base/Breadcrumb";

export enum LicenciaUsuarioEditTabs {
    Datos = "Datos",
    Accesos = "Accesos",
}

interface LicenciaContenidosEditModelEx extends LicenciaContenidosEditModel {
}

export class EditComp extends EditPageBase<LicenciaContenidosEditModelEx> {

    protected _setPageTitleForNew(): string {
        return "Nueva asignación de licencia de alumno"
    }

    protected _setPageTitleForExisting(editModel: LicenciaContenidosEditModelEx): string {
        return `Ver asignación de licencia de alumno`;
    }

    protected _createApiService(): IServiceForEdit<LicenciaContenidosEditModel> {
        return new LicenciasColegioService() as any as IServiceForEdit<LicenciaContenidosEditModel>;
    }

    private _paintMainForm(): JSX.Element {

        return <React.Fragment>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.numPedido)}>{I18n.Strings.licenciasContenido.numPedido}</Label>
                        <Input
                            type="text"
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.numPedido = evt.target.value;
                                this.setState(state);
                            }}
                            disabled={this.props.viewMode}
                            className={"form-control "}
                            name={nameof(this.state.editModel.numPedido)}
                            id={"licencias" + nameof(this.state.editModel.numPedido)}
                            value={this.state.editModel.numPedido || ""}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.fechaPedido)}>{I18n.Strings.licenciasContenido.fechaPedido}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            className={"form-control "}
                            name={nameof(this.state.editModel.fechaPedido)}
                            id={"licencias" + nameof(this.state.editModel.fechaPedido)}
                            value={I18n.Strings.formatDate(this.state.editModel.fechaPedido) || ""}
                        />
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <ClasesDropDown
                            label={I18n.Strings.matriculas.nombreClase}
                            disabled={this.props.viewMode}
                            selectedItemValue={this.state.editModel.claseId}
                            selectedItemText={this.state.editModel.clase}
                            profesorId={ClientContext.Current.usuarioId}
                            onChanged={(claseId, clase, item) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.claseId = claseId;
                                state.editModel.clase = clase;
                                state.editModel.claseMateria = null;
                                state.editModel.claseMateriaId = null;
                                state.editModel.editorial = null;
                                this.setState(state);
                            }}
                        />

                        {this._errorMessage(nameof(this.state.editModel.claseId))}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="8">
                    <ClaseMateriaDropDown
                        label={I18n.Strings.matriculas.nombreMateria}
                        disabled={this.props.viewMode}
                        key={this.state.editModel.claseId}
                        selectedItemValue={this.state.editModel.claseMateriaId}
                        claseId={this.state.editModel.claseId}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.claseMateriaId))}
                        selectedItemText={this.state.editModel.claseMateria}
                        onChanged={(materiaId, materia, item) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.claseMateriaId = materiaId;
                            state.editModel.claseMateria = materia;
                            //state.editModel.editorial = item == null || item.data == null ? null : (item.data as ClaseMateriaListItem).editorial;

                            this.setState(state);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <ContenidosPicker
                        label={I18n.Strings.licenciasContenido.contenido}
                        key={this.state.editModel.claseId}
                        disabled={this.props.viewMode}
                        required={true}
                        itemId={this.state.editModel.contenidoId}
                        itemName={this.state.editModel.contenido}
                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => {
                            var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                            return `${enumName} - ${item.nombre}`;
                        }}
                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        nivelEducativoId={null}
                        claseId={this.state.editModel.claseId}
                        excluirLicenciasUsuarioId={this.state.editModel.usuarioId}
                        tipoUsuario={TipoUsuarioContenido.Alumno}
                        mostrarNiveles={true}
                        mostrarSoloColegio={false}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.contenidoId))}
                        onSelected={(contenidoId, contenido, item) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.contenidoId = contenidoId;
                            state.editModel.contenido = contenido;
                            state.editModel.editorial = item == null ? null : item.editorial;

                            this.setState(state);
                        }}
                    />
                </Col>
                <Col md="3">
                    <Label for={"licencias" + nameof(this.state.editModel.isbn)}>{I18n.Strings.contenidos.isbn}</Label>
                    <Input
                        type="text"
                        required={true}
                        disabled={this.props.viewMode}
                        className={"form-control"}
                        name={nameof(this.state.editModel.isbn)}
                        id={"usuarios" + nameof(this.state.editModel.isbn)}
                        value={this.state.editModel.isbn || ""}
                    />
                </Col>
                <Col md={3}>

                    <FormGroup>
                        <Label for={"licencias" + nameof(this.state.editModel.editorial)}>{I18n.Strings.licenciasContenido.editorial}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            className={"form-control"}
                            name={nameof(this.state.editModel.editorial)}
                            id={"licencias" + nameof(this.state.editModel.editorial)}
                            value={this.state.editModel.editorial || ""}
                        />
                    </FormGroup>
                </Col>

            </Row>
            {this.state.editModel.tipoAcceso != TipoAcceso.NoAccedeNoMuestraCredenciales &&
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for={"licencias" + nameof(this.state.editModel.tipoAcceso)}>{I18n.Strings.colegiocredenciales.tipoLicencia}</Label>
                            <TipoAccesoDropDown
                                id={nameof(this.state.editModel.tipoAcceso)}
                                disabled={this.props.viewMode}
                                editorial={this.state.editModel.editorial}
                                className={"form-control " + this._errorClass(nameof(this.state.editModel.tipoAcceso))}
                                onChange={(tipoLicencia: string) => {
                                    var newState = this._cloneStateForSetState();
                                    if (tipoLicencia != '' && tipoLicencia != null) {
                                        newState.editModel.tipoAcceso = tipoLicencia as TipoAcceso;
                                    }
                                    else {
                                        newState.editModel.tipoAcceso = null;
                                    }
                                    newState.editModel.credenciales = "";
                                    this.setState(newState);
                                }}
                                value={this.state.editModel.tipoAcceso}
                            />
                            {this._errorMessage(nameof(this.state.editModel.tipoAcceso))}
                        </FormGroup>
                    </Col>
                    {/*
                    <Col md={8}>

                        <FormGroup>

                            <CredencialesEdit
                                onChange={(value) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.credenciales = value;
                                    this.setState(state);
                                }}
                                name={nameof(this.state.editModel.credenciales)}
                                value={this.state.editModel.credenciales || ""}
                                key={this.state.editModel.editorial + " " + this.state.editModel.tipoAcceso}
                                formValidation={this.state.validationResult}
                                required={true}
                                disabled={this.props.viewMode}
                                tipoAcceso={this.state.editModel.tipoAcceso}
                                editorial={this.state.editModel.editorial}
                            />

                        </FormGroup>
                    </Col>
                */}


                </Row>
            }
        </React.Fragment>;
    }

    protected _generateForm(): JSX.Element {
        return this._paintMainForm();

    }
    protected _getEditUrl(id: number): string {
        return RouteHelper.detailMyLicenses(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listMyLicenses();
    }

    protected _createStateForNew(): Promise<GenericEditState<LicenciaContenidosEditModelEx>> {
        var model = new GenericEditState<LicenciaContenidosEditModelEx>();
        model.editModel = {
            id: 0,
            origen: OrigenLicencia.Administrador,
            tipoUsuario: TipoUsuarioContenido.Alumno,
            usuarioId: ClientContext.Current.usuarioId
        } as LicenciaContenidosEditModel;

        var srv = new MiPerfilService();
        let promData = srv.getMyEditModel();
        let modelWithDataProm = promData.then(data => {
            model.editModel.claseId = data.claseActualId;
            return model;
        });

        return modelWithDataProm;
    }




    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if (this.state.editModel.claseId == null) {
            validation.addError(nameof(this.state.editModel.claseId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoClase.grupoClase));
        }

        if (this.state.editModel.claseMateriaId == null) {
            validation.addError(nameof(this.state.editModel.claseMateriaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoContenido.materia));
        }

        if ((this.state.editModel.credenciales || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.credenciales), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.credenciales));
        }

        if (this.state.editModel.tipoAcceso == null) {
            validation.addError(nameof(this.state.editModel.tipoAcceso), I18n.Strings.validationErrors.valueRequired(I18n.Strings.licenciasContenido.tipoAcceso));
        }

        return validation;
    }

    protected updateBreadCrumb(model: LicenciaContenidosEditModel) {
        var items = [{
            text: I18n.Strings.misLicencias.title,
            link: RouteHelper.listMyLicenses()
        }];
        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: I18n.Strings.misLicencias.edit,
                link: ""
            });
        }

        NavigationHelper.setBreadcrumbItems(items);
    }

}

export const Edit = withRouter(EditComp)