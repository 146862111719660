import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col, FormGroup, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ContenidosPicker } from "../contenidos/colegio/Picker";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { RouteHelper } from "../IntranetRouter";
import { NivelContenidosListItem, NivelContenidosListItemPaginatedList, NivelContenidosSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { toCamelCase } from "../utils/ValuesFormatter";
import { NivelContenidosService } from "./NivelesContenidosService";




interface NivelContenidosSearchEx extends NivelContenidosSearch {
    cursoEscolar?: string;
    nivel?: string;
    contenido?: string;
}

export class IndexComp extends IndexPageBase<NivelContenidosSearchEx, NivelContenidosListItemPaginatedList, NivelContenidosListItem>
{

    protected _getInitialSearchParams(): NivelContenidosSearchEx {

        var search = {
            cursoEscolarId: ClientContext.getCursoEscolarActivo().id,
            contenidoId: this.props.searchModel?.contenidoId,
        } as NivelContenidosSearchEx;

        return search;
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.nivel)}>
                        {I18n.Strings.nivelesContenidos.nivel}
                    </Label>

                    <NivelEducativoDropDown
                        id={nameof(this.state.searchParams.nivelId)}

                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        value={this.state.searchParams.nivelId}

                        text={this.state.searchParams.nivel}
                        seleccionClases={false}
                        onChange={(id, nombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.nivelId = id;
                            state.searchParams.nivel = nombre;

                            this.setState(state, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <ContenidosPicker
                    label={I18n.Strings.licenciasContenido.contenido}
                    required={false}
                    itemId={this.state.searchParams.contenidoId}
                    itemName={this.state.searchParams.contenido}
                    nivelEducativoId={null}

                    getIdFromItem={(item) => item.id}
                    getNameFromItem={(item) => {
                        var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                        return `${enumName} - ${item.nombre}`;
                    }}
                    mostrarNiveles={true}
                    mostrarSoloColegio={true}

                    errorMessage={null}
                    onSelected={(contenidoId, contenido, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.contenidoId = contenidoId;
                        state.searchParams.contenido = contenido;

                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<NivelContenidosSearch, NivelContenidosListItemPaginatedList> {
        return new NivelContenidosService();
    }

    protected _getTableColumns(): TableColumn<NivelContenidosListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].etapa),
                title: I18n.Strings.etapa.etapa,
                renderField: (item) => item.etapa
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].nivelEducativo),
                title: I18n.Strings.nivelesContenidos.nivel,
                renderField: (item) => item.nivelEducativo
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].contenido),
                title: I18n.Strings.nivelesContenidos.contenido,
                renderField: (item) => item.contenido
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoAccesoPorDefecto),
                title: I18n.Strings.nivelesContenidos.tipoAccesoPorDefecto,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`TipoAcceso.${item.tipoAccesoPorDefecto}`);
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].numLicencias),
                title: toCamelCase(I18n.Strings.licenciasContenido.entityPluralName),
                renderField:(item)=> I18n.Strings.formatNumber(item.numLicencias, 0)
            },
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.nivelesContenidos.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.nivelesContenidos.description;
    }


    protected _getEditionItemUrl(item: NivelContenidosListItem): string {

        
        return RouteHelper.editNivelContenido(item.id, this.props.searchModel?.contenidoId);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createNivelesContenido(this.props.searchModel?.contenidoId);
    }

}

export const Index = withRouter(IndexComp);